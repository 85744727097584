import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "batavus" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "batavus-elcykel-högklassig-elcykling-för-alla-behov"
    }}>{`Batavus Elcykel: Högklassig Elcykling För Alla Behov`}</h1>
    <p>{`Välkommen till vår dedikerade sida för Batavus elcyklar! Här hittar du ett brett sortiment av elcyklar från Batavus, en ledande tillverkare av högkvalitativa cyklar med avancerad teknologi och stilfull design. Upptäck varför Batavus är ett av de mest betrodda märkena inom elcykelvärlden och hitta den perfekta modellen för dig.`}</p>
    <h2 {...{
      "id": "batavus-elcykel-serier"
    }}>{`Batavus Elcykel Serier`}</h2>
    <h3 {...{
      "id": "batavus-fier"
    }}>{`Batavus Fier`}</h3>
    <p><strong parentName="p">{`Batavus Fier 2 Nexus Elcykel 2024`}</strong>{` är den ultimata staden följeslagaren med en toppmodern Bosch-motor och Bosch PowerPack 500 Wh batteri. Denna elassisterade lådcykel är idealisk för moderna pendlare med sina smidiga Shimano Nexus växlar och pålitliga skivbromsar. Med en imponerande räckvidd och säker körupplevelse kan du enkelt navigera genom trånga gator och upptäcka nya rutter.`}</p>
    <h3 {...{
      "id": "batavus-luca-lx"
    }}>{`Batavus Luca Lx`}</h3>
    <p><strong parentName="p">{`Batavus Luca Lx 2023`}</strong>{` kombinerar hög prestanda med stilren design. Med en Bosch Active Line mittmotor och Bosch PowerPack 400 Wh batteri erbjuder denna elcykel upp till 70 km räckvidd på en laddning. Shimanos Nexus 7 växlar, integrerad belysning och bekväm dämpning gör Luca Lx idealisk för både vardagspendling och längre utflykter. Finns i flera färger och storlekar.`}</p>
    <h3 {...{
      "id": "batavus-zonar"
    }}>{`Batavus Zonar`}</h3>
    <p>{`För den sportiga cyklisten erbjuder `}<strong parentName="p">{`Batavus Zonar Elcykel 2024`}</strong>{` både dam- och herrmodeller utrustade med Bosch Performance Line Speed Gen4 motorer och robusta 400 Wh batterier. Den höga topphastigheten, avancerade Shimano Deore T6000 växlar och pålitliga skivbromsar gör Zonar-serien perfekt för dynamisk stads- och landsbygdscykling.`}</p>
    <h3 {...{
      "id": "batavus-torino-ego-lx"
    }}>{`Batavus Torino Ego Lx`}</h3>
    <p><strong parentName="p">{`Batavus Torino Ego Lx`}</strong>{` är en elegant damelcykel med låg insteg och kraftfull Ananda 250W framhjulsmotor. Med Shimano Nexus 7 växlar och EGO SMART 400 Wh batteri erbjuder denna modell en räckvidd på upp till 70 km. Perfekt för stadskörning med sin intuitiva EGO LCD-display, stabila skiv- och fälgbromsar och integrerad belysning.`}</p>
    <h3 {...{
      "id": "batavus-harlem"
    }}>{`Batavus Harlem`}</h3>
    <p><strong parentName="p">{`Batavus Harlem Herr Elcykel 2024`}</strong>{` kombinerar Bosch mittmotor och Shimano Nexus 7 växlar för en smidig färd. Med sin eleganta svarta design och stabila ram är denna modell idealisk för både stadsmiljö och längre turer. Komponenten såsom skivbromsar och 400 Wh batteri som varar upp till 70 km per laddning, garanterar hög säkerhet och komfort.`}</p>
    <h3 {...{
      "id": "batavus-razer"
    }}>{`Batavus Razer`}</h3>
    <p>{`Den moderna `}<strong parentName="p">{`Batavus Razer Herr Elcykel 2024`}</strong>{` är utrustad med Bosch Active Line Plus motor och Bosch PowerPack 500 Wh batteri, vilket gör den perfekt för krävande pendling. Toppmodern design med intuitiv Bosch Purion display, Tektro skivbromsar och en räckvidd upp till 80 km per laddning.`}</p>
    <h2 {...{
      "id": "köpguide-för-batavus-elcyklar"
    }}>{`Köpguide för Batavus Elcyklar`}</h2>
    <h3 {...{
      "id": "vad-ska-du-tänka-på"
    }}>{`Vad ska du tänka på?`}</h3>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Syfte och användning`}</strong>{`: Om du pendlar dagligen i stadsmiljö, är modeller som `}<strong parentName="li">{`Batavus Luca Lx`}</strong>{` och `}<strong parentName="li">{`Batavus Harlem`}</strong>{` utmärkta val. För längre distanser och sportigt bruk, kolla in `}<strong parentName="li">{`Batavus Zonar`}</strong>{` eller `}<strong parentName="li">{`Batavus Razer`}</strong>{`.`}</li>
      <li parentName="ol"><strong parentName="li">{`Motor och batteri`}</strong>{`: Välj en modell med Bosch motor för kraftfull och pålitlig prestanda. Batteriets kapacitet (Wh) påverkar räckvidden direkt.`}</li>
      <li parentName="ol"><strong parentName="li">{`Växelsystem`}</strong>{`: Nexus växelsystem är lätt att använda och minimalt underhållskrävande, perfekt för stadscykling. För terräng och sportigare cykling är Shimano Deore att föredra.`}</li>
      <li parentName="ol"><strong parentName="li">{`Komfort och säkerhet`}</strong>{`: Dämpning, integrerad belysning, skivbromsar och ergonomisk design ökar både komfort och säkerhet.`}</li>
    </ol>
    <h3 {...{
      "id": "slutsats"
    }}>{`Slutsats`}</h3>
    <p>{`Batavus erbjuder elcyklar av hög kvalitet med ett brett utbud av funktioner och specifikationer för att möta dina cykelbehov. Oavsett om du är ute efter en pålitlig pendlarcykel eller en kraftfull sportcykel, har Batavus rätt modell för dig. Utforska vårt sortiment för att hitta den perfekta elcykeln som passar din livsstil och njut av den ultimata cykelupplevelsen.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      